<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full">
                            <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                <span class="text-secondary-darkblue">
                                    Product Library
                                </span>
                            </div>
                            <div class="flex items-center text-secondary-darkgrey">
                                <div v-for="actionWithFields in response.body.actions.filter(action => action.name !== 'filter'  && !action.fields.isEmpty())"
                                     class="mr-1">
                                    <upload-products-form v-if="actionWithFields[0] === 'add-products-via-csv'" :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></upload-products-form>
                                    <form-action v-else :action="actionWithFields[1]"
                                                 :actionKey="actionWithFields[0]"
                                                 :redirectOnSuccess="true"
                                                 :onSuccessPath="$router.currentRoute.fullPath"
                                    ></form-action>
                                </div>
                            </div>
                        </div>

                    </page-header>
                    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <section class="lg:col-start-1 lg:col-span-3">
                            <product-category-list :withoutTypeLink="hasLink(response, 'no-type') " :response="response" :categories="response.body.entities.filter(entity => entity.rels.contains('product-category'))"></product-category-list>
                        </section>
                    </div>
                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import Button from "@/v2/buttons/Button";
import CategoryPaginatedList from "@/v2/paginatedLists/CategoryPaginatedList.vue";
import FetchSirenRoot from "@/components/FetchSirenRoot";
import FormAction from "@/v2/components/FormAction";
import Loading from "@/components/Loading";
import MainTemplate from "@/v2/templates/MainTemplate";
import PageHeader from "@/v2/components/Header";
import ProductCategoryList from "@/components/ProductCategoryList.vue";
import ProductListPaginatedList from "@/v2/paginatedLists/ProductListPaginatedList.vue";
import UploadProductsForm from "@/components/CustomForms/UploadProductsForm.vue";
export default {
    name: "ProductCategories",
    data() {
        return {
            loading: false
        }
    },
    components: {
        ProductCategoryList,
        CategoryPaginatedList,
        ProductListPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
        UploadProductsForm
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToProductLibrary(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('product-library')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        hasLink(response, linkName){
            return response.body.links.filter(link => link.rels.contains(linkName)).first();
        },
    }
}
</script>

<style scoped>

</style>
